@import "../../scss/config";

.custom-avatar-img {
	display: grid;
	justify-items: center;
	.dashed-personal-img ,
	.after-crop-wrap {
		width: 102px;
		height: 102px;
		color: #262d5f;
		border-radius: 50%;
		border: 2px dashed rgba(0, 0, 0, 0.2);
		cursor: pointer;
		display: grid;
		place-items: center;
		position: relative;
		box-shadow: 4px 6px 8px rgba(#000, 0.1);
		background-color: #ebebeb;

		.initial-photo {
			width: 98px;
			height: 98px;
			border-radius: 50%;
			object-fit: cover;
			object-position: top center;
		}

		.avatar-placeholder {
			width: 98px;
			height: 98px;
			color: #9f9f9f;
			background-color: #ebebeb;
		}
		.after-crop-img {
			width: 92px;
			height: 92px;
			border-radius: 50%;
			object-fit: cover;
			object-position: top center;
		}
		.add-icon {
			position: absolute;
			bottom: -8px;
			left: -8px;
			color: $main-app-color;
			width: 32px;
			height: 32px;
		}

		&.err-border {
			border-color: #ec8e8e;
		}
	}

	&.ltr {
		.dashed-img {
			.add-icon {
				right: -8px;
			}
		}
	}

	.err-p {
		font-size: 13px;
		color: #ec8e8e;
		margin-top: 8px;
		text-align: center;
	}
}
