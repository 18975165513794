.designer-projects {
	display: grid;
	grid-gap: 32px;
	max-width: 1100px;
}

.order-status {
	display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
	padding-bottom: 15px;
}
.order-status-header {
	width: 250px;
	height: 50px;
	border-radius: 5px;
	background: #d50606;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	margin-bottom: 30px;
	margin-top: 20px;
}

.order-steps {
	display: flex;
	gap: 15px;
	min-width: 100%;
	width: max-content;

}
.order-steps-details {
	display: flex;
	width: 99%;
	background: white;
	padding: 10px;
	border-radius: 44px;
	height: max-content;
	align-items: center;
	justify-content: space-between	;
}


.notActive-step {
	color:gray
}
.completed-step {
	color: #10D229;
	position: relative;
}
.active-step {
	color: #D50606

}

.order-steps-details >div {
	width: 100px;
	display: flex;
	justify-content: center;
	height: max-content;
}


.order-steps-details >div:nth-child(1),.order-steps-details >div:nth-child(3)  {

	width: 50px;
}

.order-steps-details >div:nth-child(2) {
	width: 300px;
	// white-space: nowrap;
    // overflow: hidden !important;
    // text-overflow: ellipsis;
    // display: inline-block;
}
.order-steps-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
	position: relative;
	margin: 0 30px;
	justify-content: center;
	background: #f8f8f8;
}

.steps-line {
	width:5px ;
	height:70px;
	// height: 100%;
	background-color: gray;
}

.completed-step-line {
	background-color: #10D229;

}

.mt-20 {
	margin-top: 20px;
}

.order-steps-container {
	display: flex;
	flex-direction: column;
	gap:50px
}


.completed-step .order-steps-icon::before {
	content: "";
	width: 50px;
	height: 50px;
	border: 2px solid #10D229;
	position: absolute;
	border-radius: 100%;
}

.order-steps-icon::after{
	content: "";
    width: 3px;
    height: 71px;
    background-color: gray;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 21%);
}
.completed-step .order-steps-icon::after {
    content: "";
	width: 3px;
    height: 58px;
    background-color: #10D229;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 44%);
}

.active-step .order-steps-icon::after{
    background-color: #D50606 !important

}

.order-steps-icon::after{
	content: "";
    width: 3px;
    height: 71px;
    background-color: gray;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 21%);
}


.order-steps:last-child  .order-steps-icon::after{
width: 0 !important;
height: 0 !important;

}