@import '../../../scss/config';

.application-step-two-two {
  .wrap-title {
    color: $main-app-color;
    font-size: 20px;
    margin-bottom: 12px;
  }

  .profession-wrap {
    .checkboxs-title {
      margin-bottom: 12px;
      color: $main-app-color;
      font-size: 18px;
    }
  }

  .checkboxs-wrap {
    display: grid;
    gap: 8px;
  }

  .attributes-wrapper {
    display: grid;
    gap: 4px;
    .checkbox-input-wrap {
      margin-bottom: 8px;
      label {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
    .attributes-voices-wrapper {
      border-bottom: 1px solid rgba(#000, 0.15);
      margin-bottom: 12px;
      padding-bottom: 12px;
      .checkbox-input-wrap {
        font-size: 14px;
      }

      .voice-note-input-wrapper {
        display: flex;
        align-items: center;
        padding: 0 14px;
        label {
          font-size: 13px;
        }
        // audio {
        // }
      }
    }
  }

  .gender-wrap {
    margin: 32px 0;
    .gender-labels {
      display: grid;
      grid-template-columns: 102px 102px;
      justify-content: start;
      grid-gap: 14px;
      input {
        display: none;
      }

      .gender-label {
        padding: 10px 26px;
        border-radius: 4px;
        background-color: #ebebeb;
        color: #222;
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        &.checked {
          background-color: $main-app-color;
          color: #fff;
          svg {
            path {
              fill: #fff;
            }
          }
        }

        svg {
          height: 24px;
        }
      }
    }
  }

  .voiceOver-radio-group-wrapper {
    margin: 15px 0;
    h3 {
      color: $main-app-color;
      font-size: 20px;
    }
  }
}
