@import "../../../scss/config";

.service-step-nine {
	grid-gap: 52px;
	.textarea-section {
		display: grid;
		grid-gap: 12px;
		.textarea-title {
			color: $main-app-color;
			font-size: 22px;
			font-weight: bold;
		}
	}
}
