@import "../../../scss/config";

.user-settings-wrap {
	max-width: 992px;
	background-color: #fff;
	padding: 22px 18px;
	@include mediaLg {
		padding: 38px 52px;
	}
	.main-title {
		text-align: center;
		font-size: 32px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 42px;
	}
	.settings-form {
		display: grid;
		grid-gap: 42px;
		@include mediaLg {
			grid-template-columns: 1fr 1fr;
		}
		align-items: center;
		align-content: start;

		.avatar-wrap {
			grid-column: 1 / -1;
			display: grid;
			justify-content: center;
			margin-bottom: 14px;
		}

		.gender-wrap {
			.gender-labels {
				display: grid;
				grid-template-columns: 102px 102px;
				justify-content: start;
				grid-gap: 14px;
				input {
					display: none;
				}

				.gender-label {
					padding: 10px 26px;
					border-radius: 4px;
					background-color: #ebebeb;
					color: #222;
					display: grid;
					grid-auto-flow: column;
					justify-content: center;
					gap: 10px;
					cursor: pointer;
					&.checked {
						background-color: $main-app-color;
						color: #fff;
						svg {
							path {
								fill: #fff;
							}
						}
					}

					svg {
						height: 24px;
					}
				}
			}
		}
	}
}


.p-absolute {
	position: absolute;
}