@import "../../../scss/config";

.service-step-eight {
  grid-gap: 32px;

  .checkBoxs-title-wrap {
    display: grid;
    .checkboxs-title {
      color: $main-app-color;
      font-size: 22px;
      font-weight: bold;
    }

    .checkboxs-wrap {
      display: grid;
      gap: 8px;
      margin-top: 8px;

      label {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }

    .other-field {
      margin-top: 12px;
    }
  }
}
