.application-page {
	.steps-wrapper {
		background-color: #f8fffc;
		border: 1px solid rgba(#000, 0.08);
		border-radius: 6px;
		padding: 32px 28px;

		.formik-step {
			display: grid;
			align-content: start;
			max-width: 700px;

			.step-title {
				grid-column: 1 / -1;
				text-align: center;
				font-size: 28px;
				margin-bottom: 28px;
			}
		}
	}
}
