@import "../../scss/config.scss";
.logo-link {
  display: grid;
  align-items: center;
  img {
    height: calc(#{$headerMd-height} - 22px);
    @include mediaLg {
      height: calc(#{$headerLg-height} - 8px);
    }
    @include mediaXlg {
      height: calc(#{$headerXlg-height} - 8px);
    }
    @include mediaXXlg {
      height: calc(#{$headerXXlg-height} - 8px);
    }
  }
}
