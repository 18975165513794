#recaptcha-container {
  display: none;
}
@import "./config";
@import "./common";
@import "./modals";

html {
  scroll-behavior: smooth;
  color: $main-black-color;

  &[lang="ar"] {
    @include bodyFont-ar;
    button {
      @include bodyFont-ar;
    }
  }
  &[lang="en"] {
    @include bodyFont-en;
    button {
      @include bodyFont-en;
      font-weight: bold;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  @include custom-scrollbar;
}

.app {
  min-height: 100vh;
  // background-color: #f5f5f5;
  // display: grid;
  grid-template-rows: auto 1fr;

  // &.app-ltr {
  // 	@include bodyFont-en;
  // }

  &.app-rtl {
    @include bodyFont-ar;
  }

  &.lightApp {
    color: #393939;
    ::selection {
      background-color: rgba(#3fd4e2, 0.4);
      color: #424242;
    }
  }
  &.darkApp {
    color: #cdcdcd;
    // a {
    // 	color: #cdcdcd;
    // }
    ::selection {
      background-color: rgba(#455268, 0.5);
      color: #cdcdcd;
    }
  }
  margin: 0;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @import "./css/fontello.css";
  @import "./css/ionicons.css";
}

[contenteditable="true"] {
  line-height: 1.8;
  // display: grid;
  // align-items: center;
}

[contenteditable="true"][placeholder]:empty:before {
  content: attr(placeholder);
  color: gray;
  padding: 4px 12px;
  cursor: text;
}

[contenteditable="true"][placeholder]:focus {
  outline: 0;
}

[contenteditable="true"][placeholder] {
  padding: 6px 12px;
}

.react-tel-input {
  direction: ltr;
  max-height: 48px;
  & > .form-control {
    background-color: transparent;
    width: 100%;
    min-width: 300px;
  }
  .flag-dropdown {
    padding-top: 2px;
    .flag {
      .arrow {
        position: absolute;
      }
    }
  }
  .MuiFormControl-root.MuiTextField-root {
    height: 100%;

    .MuiInputBase-root.MuiInput-root {
      padding-left: 58px;
      height: 100%;
    }
  }
}
.react-tel-input .form-control {
  border: none;
  padding: 0;
}



.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.4) !important;
}