@import "../../../scss/config";
.designer-home {
  background-color: #fff;
  padding: 32px 42px;
  border-radius: 5px;
  min-height: 60vh;
  .username {
    color: $main-app-color;
  }

  .main-content {
    display: grid;
    justify-items: center;
    grid-gap: 32px;
    margin-top: 32px;
    .doc-icon {
      height: 122px;
    }
    .apply-text {
      color: #b7b7b7;
    }
  }
}

.w-100{
  width: 100%;
}
