@import "../../scss/_config";

.user-signup-section {
  display: grid;
  grid-gap: 32px;
  @include mediaXlg {
    grid-template-columns: 1fr 45%;
  }

  .section-img {
    pointer-events: none;
    @include mediaXlg {
      // transform: translateY(-120px);
      margin-top: -120px;
    }
    display: grid;
    img {
      max-width: 100%;
      animation: slideUserMeIn 0.5s ease-out both;
    }
    @keyframes slideUserMeIn {
      to {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }
    }
    &.rtl {
      img {
        transform: translateX(-100%);
      }
    }
    &.ltr {
      img {
        transform: translateX(100%);
      }
    }
  }

  .title-form-section {
    .section-title {
      font-size: 42px;
      text-align: center;
      margin-bottom: 42px;
    }
  }

  .user-signup-form {
    display: grid;
    grid-gap: 22px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
      grid-gap: 32px 42px;
    }

    .terms-conditions-wrap {
      color: #b2b2b2;
      grid-column: 1 / -1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px;
      label {
        margin: 0 !important;
      }
      .terms-btn {
        font-size: 15px;
        text-decoration: underline;
        cursor: pointer;
        &.error {
          color: red;
        }
      }
    }
    .phone-number-wrap {
      position: relative;
      display: grid;
      grid-template-rows: 1fr auto;
      
      .MuiFormHelperText-root.Mui-error {
        // position: absolute;
        margin-top: 4px;
        align-self: end;
      }
      label {
        font-weight: bold !important;
      }
    }
    .phone-field-wrapper {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: start;
      .code-wrap {
        direction: ltr;
        height: 48px;
        display: flex;
        align-items: flex-end;
        .code-span {
          background-color: #eee;
          padding: 3px 5px;
          display: flex;
          height: 32px;
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.54);
          color: #fff;
          font-size: 14px;
        }
        &.rtl {
          .code-span {
            margin-left: 10px;
          }
        }
        &.ltr {
          .code-span {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .btn-more-options {
    margin-top: 32px;
    display: grid;
    grid-gap: 18px;
    @include mediaLg {
      grid-auto-flow: column;
    }
    justify-content: center;
    align-items: center;
    .submit-btn {
      padding: 14px 42px;
    }
    .more-social-options {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 12px;
      align-items: center;
      color: #b2b2b2;

      .options-ul {
        display: grid;
        grid-gap: 18px;
        grid-auto-flow: column;
        align-items: center;
        li {
          display: grid;
          cursor: pointer;
        }
        img {
          width: 32px;
          background-color: #fff;
          box-shadow: 2px 6px 8px rgba(#000, 0.1);
          border-radius: 3px;

          &.google-img {
            width: 38px;
            padding: 5px;
          }
        }
      }
    }
  }
  .have-account {
    margin-top: 32px;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    grid-gap: 8px;
    font-size: 15px;
    .pp,
    span {
      color: #b2b2b2;
    }

    .signin-link {
      color: $main-app-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.phone-number-wrap label {
  font-weight: normal !important;
}
.react-tel-input {
  font-family: "AndikaNewBasic-Regular" !important;
  font-weight: normal !important;

}
.app.app-rtl .react-tel-input{
  font-family: "Janna-LT-Bold" !important;
  font-weight: normal !important;
  }


  html[lang="en"] .flag-dropdown {
    left: initial; 
    right: 0 !important;
  }

  html[lang="en"]  .react-tel-input .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiInput-root {
    padding-left: 0 !important;
  }