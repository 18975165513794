@import '../../scss/config';

.signup-page {
  padding-bottom: 62px;
  overflow-x: hidden;
  background-color: #f1f2f3;
  min-height: 100vh;
  .page-wrapper {
    .logo-lang-btn {
      display: grid;
      grid-gap: 32px;
      @include mediaXlg {
        grid-template-columns: 1fr 45%;
      }
      // justify-content: space-between;
      // align-items: center;
      margin-top: 32px;
      .lang-btn {
        align-self: start;
        justify-self: center;
        @include mediaXlg {
          justify-self: end;
        }
        img {
          width: 32px;
        }
      }

      .logo-signup-links {
        display: grid;
        justify-items: center;
        margin-bottom: 32px;
      }

      .signup-links {
        margin-top: 42px;
        display: grid;
        @include mediaLg {
          grid-auto-flow: column;
        }
        justify-content: space-between;
        grid-gap: 22px;

        .user-signup-link,
        .developer-signup-link,
        .supervisor-signup-link {
          background-color: $main-app-color;
          color: #000;
          padding: 22px 44px;
          border-radius: 4px;
          position: relative;
          transition: all 0.2s ease-out;
          text-align: center;

          &.active,
          &:hover {
            // background-color: #ff131f;
            // color: #fff;
            // box-shadow: 0 12px 14px rgba(#000, 0.1);
            color: #ff131f;
            background-color: #fff;
            border: 1px solid #ff131f;

          }

          &.active {
            &::after {
              content: '';
              position: absolute;
              top: calc(100% + 8px);
              width: 12px;
              height: 12px;
              border-radius: 50%;
              left: 50%;
              transform: translate(-50%, 22px);
              pointer-events: none;
              background-color: #ff131f;
              opacity: 0;
              visibility: hidden;
              pointer-events: none;
              animation: animateAfter 0.3s cubic-bezier(0.94, 0.21, 0.39, 1.71)
                both;
            }

            @keyframes animateAfter {
              0% {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transform: translate(-50%, -22px);
              }

              100% {
                opacity: 1;
                visibility: visible;
                pointer-events: all;
                transform: translate(-50%, 0);
              }
            }
          }
        }
      }
    }
  }
  .phone-number-wrap.error-mobile label{
    color: #ff131f;

  }
}
