@import "../../../scss/config";

.service-step-three {
  grid-gap: 32px;

  .voiceOver-radio-group-wrapper {
    display: grid;
    .wrap-two {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
    }
    .group-title {
      color: $main-app-color;
      font-size: 22px;
      font-weight: bold;
    }
  }
  .models-wrapper {
    background-color: rgb(251, 244, 230);
    width: fit-content;
    height: max-content;
    padding: 22px 18px;
    border-radius: 5px;
    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
    }
    label {
      font-size: 15px;
      color: #424242;
    }
    svg {
      width: 28px;
      height: 28px;
    }
  }
  .models-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
