@import "../../scss/config";
@mixin service-btn-shadow {
  box-shadow: 4px 8px 22px rgba(#4148ff, 0.14);
}

.service-card-loader-box {
  svg {
    display: grid;
  }
  @include mediaXlg {
    grid-column-end: span 2;
  }
  @include mediaXXlg {
    grid-column-end: span 1;
  }
}

.service-card {
  background-size: cover;
  @include service-btn-shadow;
  min-height: 222px;
  padding: 32px;
  position: relative;
  border-radius: 10px;
  .service-name {
    text-align: center;
    color: #fff;
    font-size: 20px;
    position: relative;
    z-index: 2;
    margin-bottom: 18px;
  }
  .service-p {
    text-align: center;
    color: #fff;
    font-size: 16px;
    position: relative;
    z-index: 2;
    margin-bottom: 8px;
  }

  @include mediaXlg {
    grid-column-end: span 2;
  }
  @include mediaXXlg {
    grid-column-end: span 1;
  }
  .service-btns {
    position: absolute;
    bottom: -20px;
    right: 32px;
    left: 32px;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    z-index: 2;

    .service-link {
      border: 2px solid $main-app-color;
    }

    .service-link,
    .play-btn {
      display: grid;
      place-items: center;
      @include service-btn-shadow;
      background-color: #fff;
      cursor: pointer;
      transition: all 0.25s ease-out;
      svg {
        path {
          transition: all 0.25s ease-out;
        }
      }

      &:hover {
        color: #fff;
        background-color: darken($main-app-color, 2%);
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .service-link {
      line-height: 1;
      padding: 18px 16px;
      border-radius: 5px;
      font-weight: bold;
      @include mediaXXlg {
        // padding: 18px 28px;
        // font-size: 18px;
      }
    }

    .play-btn {
      padding: 14px 18px;
      border-radius: 6px;
      line-height: 1;
      svg {
        height: 18px;
        // @include mediaXXlg {
        // 	height: 28px;
        // }
      }
    }
  }

  &:nth-of-type(1) {
    background-color: #959595;
  }
  &:nth-of-type(2) {
    background-color: #d50606;
  }
  &:nth-of-type(3) {
    background-color: #000000;
  }
  &:nth-of-type(4) {
    background-color: #ffffff;
    .service-name,
    .service-p {
      color: #000;
    }
  }
  &:nth-of-type(5) {
    background-color: #000000;
  }
  &:nth-of-type(6) {
    background-color: #fefbe0;
    .service-name,
    .service-p {
      color: #000;
    }
  }
  &:nth-of-type(7) {
    background-color: #959595;
    @include mediaXlg {
      grid-column: 2 / 4;
    }
    @include mediaXXlg {
      grid-column: unset;
    }
  }
  &:nth-of-type(8) {
    background-color: #d50606;
  }
  .overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    opacity: 0.5;
  }
}

.service-container {
  @include service-btn-shadow;
  border-radius: 10px;
  .service-description {
    margin-top: 36px;
    padding: 7px;
    padding-bottom: 10px;
    .service-name {
      font-size: 23px;
      margin-bottom: 10px;
      text-align: center;
      color: $main-app-color;
    }
  }
}
.video-modal {
  .close-btn {
    width: 28px;
    height: 28px;
    position: absolute;
    top: -14px;
    left: -14px;
    border: 0;
    outline: 0;
    background-color: #333;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  video {
    max-width: 90vw;
  }
}
