@import "../../scss/config";

.home-app-bar-wrapper {
	// @include mainAppBarHeight;
	display: grid;
	align-items: center;
	grid-template-columns: auto 1fr;
	grid-gap: 12px;
	@include mediaLg {
		grid-gap: 32px;
	}

	.lang-signup-links {
		display: grid;
		justify-content: end;
		grid-auto-flow: column;
		grid-gap: 22px;
	}

	// .logo-link {
	// 	display: grid;
	// 	align-items: center;
	// 	img {
	// 		height: calc(#{$headerMd-height} - 28px);
	// 		@include mediaLg {
	// 			height: calc(#{$headerLg-height} - 22px);
	// 		}
	// 		@include mediaXlg {
	// 			height: calc(#{$headerXlg-height} - 22px);
	// 		}
	// 		@include mediaXXlg {
	// 			height: calc(#{$headerXXlg-height} - 22px);
	// 		}
	// 	}
	// }

	// .lang-btn {
	// 	display: grid;
	// 	place-content: center;
	// 	grid-auto-flow: column;
	// 	justify-self: end;
	// 	cursor: pointer;

	// 	img {
	// 		width: 22px;
	// 		border-radius: 2px;
	// 	}
	// }

	.signin-signup-links {
		color: #b2b2b2;
		align-items: center;
		justify-self: end;
		display: grid;
		grid-auto-flow: column;
		grid-gap: 16px;

		.signin-link {
			color: #b2b2b2;
			font-size: 14px;
			display: none;
			@include mediaMd {
				font-size: 16px;
			}
			align-items: center;
			gap: 3px;
			@include mediaLg {
				gap: 6px;
				display: flex;
			}
			transition: all 0.25s ease-out;
			svg {
				width: 14px;
				@include mediaLg {
					width: 18px;
				}
				path {
					transition: all 0.25s ease-out;
				}
			}
			&:hover {
				color: $main-app-color;
				svg {
					path {
						fill: $main-app-color;
					}
				}
			}
		}
		.or-span {
			display: none;
			@include mediaLg {
				display: block;
			}
		}
		.signup-link {
			// display: none;
			// @include mediaMd {
			// 	display: block;
			// }
		}
	}
}

.lang-menu {
	.MuiMenu-list {
		li {
			display: flex;
			gap: 8px;
			align-items: center;

			img {
				width: 22px;
			}
		}
	}
}
.fixed-whatsapp {
	position: fixed;
	top: 80%;
	right:30px;
	display: block;
	z-index: 1000;
	width: max-content;
	padding: 8px;
    border-radius: 10px;
    display: flex;
    background: white;
    border: 1px solid #25D366;
}
.fixed-whatsapp svg {
	fill: #25D366;
}
html[dir="rtl"] .fixed-whatsapp {
	left:0px;
	left: 30px;
	right: initial

}