@import '../../scss/config';

.main-app-bar-wrapper {
  // @include mainAppBarHeight;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-gap: 12px;
  @include mediaLg {
    grid-gap: 32px;
  }

  .app-bar-navs {
    display: none;
    @include mediaLg {
      display: grid;
    }
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 28px;
    align-items: center;

    .profile-link {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $main-app-color;
      display: grid;
      place-items: center;
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        object-fit: cover;
        object-position: top center;
      }
    }

    .nav-link {
      display: grid;
      place-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(#8d8d8d, 0.15);
      cursor: pointer;
      transition: all 0.3s ease-out;
      svg {
        width: 20px;
        path,
        circle,
        ellipse {
          fill: #8d8d8d;
          transition: all 0.2s ease-out;
        }
      }

      &.cart-link {
        svg {
          path,
          circle,
          ellipse {
            fill: none;
            stroke: #8d8d8d;
          }
        }
        &.active-nav {
          svg {
            path,
            circle,
            ellipse {
              fill: none;
              stroke: #fff;
            }
          }
        }
        &:hover {
          svg {
            path,
            circle,
            ellipse {
              fill: none;
              stroke: #fff;
            }
          }
        }

        position: relative;
        .badge-span {
          background-color: #73af00;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          position: absolute;
          top: -2px;
          right: -2px;
        }
      }

      &:hover,
      &.active-nav {
        background-color: $main-app-color;
        box-shadow: 2px 3px 5px rgba(#000, 0.1);
        svg {
          path,
          circle,
          ellipse {
            fill: #fff;
          }
        }
      }
    }
  }

  .lang-signout-link {
    display: grid;
    justify-content: end;
    grid-auto-flow: column;
    grid-gap: 22px;
  }
}

.mobile-app-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 8px 0;
  border-radius: 18px 18px 0 0;
  box-shadow: 0 -4px 6px rgba(#000, 0.1);
  z-index: $app-header-z-index;
  display: block;
  @include mediaLg {
    display: none;
  }
  .app-bar-navs {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-around;
    grid-gap: 28px;
    align-items: center;

    .profile-link {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $main-app-color;
      display: grid;
      place-items: center;
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        object-fit: cover;
        object-position: top center;
      }
    }

    .nav-link {
      display: grid;
      place-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(#8d8d8d, 0.15);
      cursor: pointer;
      transition: all 0.3s ease-out;
      svg {
        width: 20px;
        path,
        circle,
        ellipse {
          fill: #8d8d8d;
          transition: all 0.2s ease-out;
        }
      }

      &.cart-link {
        svg {
          path,
          circle,
          ellipse {
            fill: none;
            stroke: #8d8d8d;
          }
        }
        &.active-nav {
          svg {
            path,
            circle,
            ellipse {
              fill: none;
              stroke: #fff;
            }
          }
        }
        &:hover {
          svg {
            path,
            circle,
            ellipse {
              fill: none;
              stroke: #fff;
            }
          }
        }
      }

      &:hover,
      &.active-nav {
        background-color: $main-app-color;
        box-shadow: 2px 3px 5px rgba(#000, 0.1);
        svg {
          path,
          circle,
          ellipse {
            fill: #fff;
          }
        }
      }
    }
  }
}
