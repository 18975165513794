.lang-btn {
	display: grid;
	place-content: center;
	grid-auto-flow: column;
	justify-self: end;
	cursor: pointer;

	img {
		height: 20px;
		border-radius: 2px;
	}
}
