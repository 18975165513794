.loadingPage {
	min-height: 100vh;
	display: grid;
	place-items: center;
	img {
		width: 82px;
	}
}
.centerLoader {
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}