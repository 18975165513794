@import '../../../scss/config';

.supervisor-orders-card {
  background-color: #fff;
  padding: 14px 18px;
  border-radius: 10px;
  box-shadow: 9px 11px 12px rgba(#000, 0.1);
  display: grid;
  grid-gap: 22px;
  margin-top: 36px;

  @include mediaXlg {
    grid-template-columns: auto 1fr auto;
  }

  .mini-card {
    // min-width: 90%;
    min-width: 236px;
    min-height: 90%;
    max-width: 480px;
    justify-self: center;
    align-self: start;
    background-color: $main-app-color;
    color: #fff;
    padding: 8px 18px;
    border-radius: 12px;
    // transform: translateY(-18%);
    margin-top: calc(-36px - 8px);

    .mini-title {
      text-align: center;
    }

    .mini-badge {
      display: grid;

      .badge-label {
        margin-top: 12px;
        background-color: #fff;
        color: $main-app-color;
        text-align: center;
        border-radius: 8px;
        padding: 12px 14px;
        line-height: 1;
        width: fit-content;
        justify-self: center;
      }

      .badge-bar-wrap {
        width: 100%;
        margin-top: 52px;

        .empty-bar {
          height: 4px;
          background-color: #111;
          border-radius: 999px;
        }

        .filled-bar {
          height: 4px;
          background-color: #fff;
          // width: 75%;
          position: relative;
          display: grid;

          .perc-span {
            position: absolute;
            justify-self: end;
            transform: translateX(-50%);
            bottom: calc(100% + 12px);
            background-color: #fff;
            padding: 7px 8px;
            color: $main-app-color;
            font-size: 12px;
            line-height: 1;
            border-radius: 6px;

            &::after {
              content: '';
              background-color: #fff;
              width: 8px;
              height: 8px;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }

          .ball-span {
            background-color: #fff;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            justify-self: end;
            top: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
          }
        }
      }

      //
      //
      //
      .start-end-wrap {
        margin-top: 22px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 28px;
        text-align: center;
      }
    }

    &.ltr {
      .mini-badge {
        .badge-bar-wrap {
          .filled-bar {
            .perc-span {
              transform: translateX(50%);
            }

            .ball-span {
              transform: translate(50%, -50%);
            }
          }
        }
      }
    }
  }

  //
  //
  //
  //

  .card-details {
    .label-span {
      color: $main-app-color;
    }

    display: grid;
    grid-gap: 8px;

    .type-number-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 22px;
    }

    .designer-name {
      display: flex;
      align-items: center;
      gap: 8px;

      .value-span {
        display: flex;
        gap: 8px;

        .job {
          color: #8b8b8b;
          font-size: 14px;
        }
      }
    }

    .chat-link {
      display: flex;
      gap: 8px;
      align-items: center;

      .value-span {
        display: grid;
        place-items: center;
        background-color: rgba(141, 141, 141, 0.15);
        border-radius: 50%;
        height: 42px;
        width: 42px;
        transition: all 0.2s ease-out;

        svg {
          transition: all 0.2s ease-out;
          fill: #8d8d8d;
          width: 20px;
        }
      }

      &:hover {
        color: $main-app-color;

        .value-span {
          background-color: $main-app-color;
          box-shadow: 0 4px 6px rgba(#000, 0.1);

          svg {
            fill: #fff;
          }
        }
      }
    }

    .duration-total-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }

    .tags-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 12px;
      color: #8b8b8b;

      .tag-wrap {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      svg {
        height: 18px;
        fill: #8b8b8b;
      }
    }
  }

  .details-link {
    padding: 12px 24px;
    background-color: $main-app-color;
    color: #fff;
    align-self: end;
    border-radius: 5px;
    width: fit-content;
    justify-self: center;
  }

  .sub-services-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  .sub-services {
    display: flex;
    align-items: center;
    gap: 15px
  }

  .service-name {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .service-name span {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    color: #8d8d8d;
  }
  .service-name svg {
    color: #8d8d8d;

  }
}