@import "../../../scss/config";

.order-stages-wrap {
	margin-top: 42px;
	display: grid;
	width: 100%;
	& > .main-title {
		background-color: $main-app-color;
		color: #fff;
		padding: 14px 34px;
		border-radius: 6px;
		width: fit-content;
	}

	.order-stages-table {
		background-color: #fff;
		padding-bottom: 22px;
		padding-top: 42px;
		margin-top: 22px;
		border-radius: 8px;
		border-spacing: 12px;
		display: grid;
		align-items: center;
		grid-gap: 42px;
		overflow-x: auto;
		width: 100%;
		.stage-row {
			display: grid;
			grid-template-columns: 92px 1fr;
			grid-gap: 6px;
			grid-auto-flow: column;
			color: #9f9f9f;
			width: 100%;
			padding: 0 18px;
			.checkmark-cell {
				display: grid;
				place-items: center;
				position: relative;
				svg {
					background-color: #fff;
					color: #9f9f9f;
					color: #bfbfbf;
					height: 28px;
					width: 28px;
					position: relative;
					z-index: 3;
				}

				&::before {
					content: "";
					position: absolute;
					width: 4px;
					background-color: #9f9f9f;
					background-color: #bfbfbf;
					border-radius: 99px;
					top: 50%;
					left: 50%;
					height: 82px;
					transform: translate(-50%, 0);
					z-index: 1;
				}
			}

			.row-details {
				width: 100%;
				text-align: center;
				background-color: #ebebeb;
				background-color: #f1f1f1;
				padding: 14px 12px;
				border-radius: 999px;
				display: grid;
				grid-template-columns: 88px 312px 92px 148px 142px;
				@include mediaXXlg {
					grid-template-columns: 82px 322px 92px 152px 152px;
				}
				@include mediaXXXlg {
					grid-template-columns: 92px 382px 92px 152px 1fr;
				}

				.stage-status {
					display: flex;
					align-items: center;
					gap: 5px;
					justify-content: center;
				}
			}

			&.done-stage {
				color: #10d229;
				.checkmark-cell {
					position: relative;
					svg {
						color: #10d229;
					}

					&::after {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						border: 3px solid #10d229;
						background-color: #fff;
						width: 82px;
						height: 82px;
						border-radius: 50%;
						pointer-events: none;
						z-index: 2;
					}
					&::before {
						background-color: #10d229;
					}
				}
			}
			&.doing-stage {
				color: $main-app-color;
				.checkmark-cell {
					svg {
						color: $main-app-color;
					}
					&::before {
						background-color: $main-app-color;
					}
				}
			}

			&:last-of-type {
				.checkmark-cell {
					&::before {
						display: none;
					}
				}
			}
		}
	}
}
