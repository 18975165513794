@import "../../../scss/config";

.service-six-step-one {
  .film-material-type {
    border-top: 1px solid rgba(#000, 0.2);
    margin-top: 12px;
    padding-top: 18px;
  }

  .film-photo-type {
    border-bottom: 1px solid rgba(#000, 0.2);
    margin-bottom: 18px;
    padding-bottom: 12px;
  }

  .group-title {
    color: $main-app-color;
  }
  .file-link-inputs-wrap {
    margin-top: 12px;
    display: flex;
    gap: 14px;
    align-items: flex-end;
  }
  .upload-wrap {
    grid-column: 1 / -1;
    .title-input-wrap {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 4px;
    }
    .upload-file-label {
      cursor: pointer;
      line-height: 1;
      background-color: #efefef;
      border-radius: 5px;
      padding: 12px 16px;
      display: flex;
      flex-wrap: wrap;
      width: fit-content;

      input {
        display: none;
      }

      .icon-text {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
  }

  .date-time-wrapper {
    margin: 22px 0;
    display: grid;
    gap: 18px;
  }
  .form-to-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
  }

  .phone-number-wrap {
    margin-bottom: 22px;
  }
}
