@import "../../scss/config";

.user-cart {
	max-width: 880px;
	padding: 22px 18px;
	@include mediaLg {
		padding: 32px;
	}
	background-color: #fff;
	overflow-x: auto;
	margin-bottom: 22px;

	.main-title {
		text-align: center;
		font-size: 38px;

		img {
			margin: 0 8px;
		}
	}
	.sub-title {
		color: $main-app-color;
		text-align: center;
		margin-bottom: 22px;
	}

	.options-wrapper {
		.options-table {
			width: 100%;
			border-spacing: 0 42px;
			th {
				text-align: start;
				white-space: nowrap;
			}
			td {
				padding: 0 42px;
				.cell-value {
					white-space: nowrap;
					width: fit-content;
					background-color: #ebebeb;
					padding: 6px 16px;
					border-radius: 5px;
					color: $main-app-color;
					&.social-value {
						display: grid;
						place-items: center;
					}
				}

				.helper-span {
					margin: 0 8px;
					display: inline-block;
				}
				&.money-value {
					color: $main-app-color;
					font-size: 18px;
					text-align: end;
				}
			}
		}
		.total-price {
			border-top: 2px solid $main-app-color;
			margin: 22px 32px;
			padding-top: 22px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			color: $main-app-color;
			grid-gap: 12px;
			font-size: 22px;

			.total-value {
				justify-self: end;
			}
		}

		.paymentBtn {
			width: fit-content;
			padding: 14px 42px;
			font-size: 18px;
			display: flex;
			gap: 8px;
			align-items: cdn;
			margin: auto;
			img {
				margin: 0 6px;
			}
		}
	}
}
