.activate-user-page {
  min-height: 100vh;
  padding: 42px 0;
  display: grid;
  place-items: center;
  background-color: #fafafa;
  .form-title {
    margin-bottom: 28px;
    text-align: center;
  }
  .title-form-wrap {
    background-color: #fff;
    width: fit-content;
    padding: 42px;
    margin: auto;
    border-radius: 12px;
  }
}
