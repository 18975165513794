@import "../../../scss/config";

.designer-projects-wrapper {
  max-width: 1100px;

  .content-filter {
    display: flex;
    margin-bottom: 32px;
    .filter-link {
      padding: 8px 22px;
      position: relative;

      &:last-of-type {
        &::after {
          display: none;
        }
      }
      &::after {
        content: "";
        width: 1px;
        background-color: rgba(#000, 0.3);
        position: absolute;
        right: 100%;
        height: 50%;
        top: 25%;
      }

      &.active {
        color: $main-app-color;
      }
    }
  }

  .empty-wrapper {
    background-color: #fff;
    border-radius: 18px;
    padding: 42px 32px;
    h2 {
      margin: auto;
      font-size: 28px;
      color: rgba(#000, 0.8);
    }
  }
}
