@import '../../../scss/config';

.application-step-two {
  .wrap-title {
    color: $main-app-color;
    font-size: 20px;
    margin-bottom: 12px;
  }

  .fields-wrapper {
    display: grid;
    gap: 18px;
  }
}
