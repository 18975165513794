.btnWithLoading {
	.img-msg {
		display: flex;
		align-items: center;
	}
	.loadingImg {
		height: 22px;
		margin-right: 6px;
	}
}
