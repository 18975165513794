@import "../../scss/_config";

.user-signin-section {
	display: grid;
	grid-gap: 32px;
	@include mediaXlg {
		grid-template-columns: 1fr 45%;
	}

	.section-img {
		pointer-events: none;
		@include mediaXlg {
			// transform: translateY(-120px);
			margin-top: -120px;
		}
		display: grid;
		img {
			max-width: 100%;
			animation: slideUserMeIn 0.5s ease-out both;
		}
		@keyframes slideUserMeIn {
			to {
				transform: translateX(0);
				opacity: 1;
				visibility: visible;
			}
		}
		&.rtl {
			img {
				transform: translateX(-100%);
			}
		}
		&.ltr {
			img {
				transform: translateX(100%);
			}
		}
	}

	.title-form-section {
		.section-title {
			font-size: 42px;
			text-align: center;
			margin-bottom: 42px;
		}
	}

	.user-signin-form {
		max-width: 400px;
		margin: auto;
		display: grid;
		grid-gap: 22px;
		@include mediaLg {
			grid-gap: 42px;
		}

		.forget-link {
			justify-self: start;
			border-bottom: 1px solid rgba(#000, 0.8);
			transition: all 0.2s ease-out;
			&:hover {
				color: $main-app-color;
			}
		}
	}

	.btn-more-options {
		margin-top: 32px;
		display: grid;
		grid-gap: 18px;
		justify-content: center;
		align-items: center;
		.submit-btn {
			padding: 14px 42px;
		}
		.more-social-options {
			display: grid;
			grid-gap: 12px;
			justify-content: center;
			align-items: center;
			color: #b2b2b2;

			.options-ul {
				display: grid;
				grid-gap: 18px;
				grid-auto-flow: column;
				justify-content: center;
				align-items: center;
				li {
					display: grid;
					cursor: pointer;
				}
				img {
					width: 32px;
					background-color: #fff;
					box-shadow: 2px 6px 8px rgba(#000, 0.1);
					border-radius: 3px;

					&.google-img {
						width: 38px;
						padding: 5px;
					}
				}
			}
		}
	}
	.dont-have-account {
		margin-top: 32px;
		display: grid;
		grid-auto-flow: column;
		justify-content: center;
		grid-gap: 8px;
		font-size: 15px;
		.pp,
		span {
			color: #b2b2b2;
		}

		.signup-link {
			color: $main-app-color;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
