.bg-red {
	background-color: #d72a33;
	color:white
}
.bg-gray {
    background-color:#ebebeb;
    color:black

}
.message-box-wrap {
    padding: 10px;
    margin: 15px 5px;
    border-radius: 13px;
    width: max-content;
}

.msg-created-at {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.flexEnd {
    display: flex;
    justify-content: end;
    padding:0 5px
}