@import "../../scss/config";

.dashboard-side-menu {
	background-color: #fff;
	border-radius: 8px;
	padding: 22px 14px;
	align-self: start;
	display: grid;
	align-content: start;
	grid-gap: 22px;
	border: 1px solid rgba(#000, 0.06);
	grid-template-columns: repeat(6, 1fr);
	overflow-x: auto;
	@include custom-scrollbar;
	@include mediaLg {
		min-height: 60vh;
		overflow-x: unset;
		grid-template-columns: 1fr;
	}

	.dashboard-tab {
		font-weight: bold;
		color: #000;
		background-color: #ebebeb;
		display: grid;
		grid-gap: 18px;
		grid-auto-flow: column;
		justify-content: space-between;
		align-items: center;
		padding: 14px 14px;
		border-radius: 6px;
		transition: background-color 0.1s ease-out, box-shadow 0.25s ease-out;
		white-space: nowrap;
		@include mediaLg {
			white-space: unset;
		}
		svg {
			height: 22px;
			// path,
			// circle,
			// ellipse {
			// 	transition: all 0.2s ease-out;
			// }
		}

		&:hover,
		&.active-tab {
			color: #fff;
			background-color: $main-app-color;
			box-shadow: 0 12px 14px rgba(#000, 0.1);
			svg {
				path,
				circle,
				ellipse {
					fill: #fff;
				}
			}

			&.settings-link {
				svg {
					path,
					circle,
					ellipse {
						fill: none;
						stroke: #fff;
					}
				}
			}
		}
	}
}
