@import "../../../scss/config";

.service-step-four {
	grid-gap: 32px;

	.radio-group-wrapper {
		.group-title {
			color: $main-app-color;
			font-size: 22px;
			font-weight: bold;
		}
	}

	.radios-img-wrapper {
		display: grid;
		gap: 12px;
		@include mediaLg {
			grid-template-columns: 1fr 1fr;
		}

		& > img {
			max-width: 100%;
		}
	}
}
