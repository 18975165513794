@import "../../scss/config";

.notification-list-item {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 12px;
	align-items: center;
	background-color: #fff;
	border-radius: 5px;
	margin-bottom: 22px;
	max-width: 900px;
	border: 1px solid rgba(#000, 0.08);
	padding: 12px 22px;
	@include mediaLg {
		padding: 18px 28px;
	}

	.MuiAvatar-root {
		width: 58px;
		height: 58px;
	}
	.MuiAvatar-img {
		width: 58px;
		height: 58px;
	}

	.notif-title {
		margin-bottom: 2px;
		color: $main-app-color;
	}
	.notif-date {
		display: flex;
		align-items: center;
		gap: 8px;
		color: #8b8b8b;
	}
	.actions-container {
		display: flex;
		gap:10px;
		margin-top: 10px;
	}
}


.notif-service {
	font-size: 14px;
	font-weight: normal;
}