.loading-modal {
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(#000, 0.5);
	display: grid;
	align-content: center;
	.modal-wrap {
		background-color: #fff;
		max-width: 522px;
		height: 212px;
		border-radius: 5px;
		margin: 32px auto;
		display: grid;
		place-items: center;
	}
}
