@import '../../../scss/config';

.application-step-five {
  max-width: 600px !important;

  .fields-wrap {
    display: grid;
    grid-gap: 32px;
  }

  .terms-conditions-wrap {
    color: $main-app-color;
    grid-column: 1 / -1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    label {
      margin: 0 !important;
    }
    .terms-btn {
      font-size: 18px;
      cursor: pointer;
      &.error {
        color: red;
      }
    }
  }
}
