@import "../../../scss/config";

.service-step-seven {
	grid-gap: 32px;

	.radio-group-wrapper {
		display: grid;
		.group-title {
			color: $main-app-color;
			font-size: 22px;
			font-weight: bold;
		}

		.other-field {
			margin-top: 12px;
		}
	}
}
