@import "../../../scss/config";

.chat-with-designer {
	& > .main-title {
		background-color: $main-app-color;
		color: #fff;
		padding: 14px 34px;
		border-radius: 6px;
		width: fit-content;
		margin: 32px 0;
	}
	.chat-wrapper {
		max-width: 900px;
		display: grid;
		grid-template-rows: 1fr auto;
		background-color: #fff;
		min-height: 600px;
		max-height: 700px;
		border: 1px solid rgba(#000, 0.1);
		border-radius: 12px 12px 4px 4px;
		transition: all 0.35s ease-out;

		.chatBody {
			overflow-y: auto;
			@include custom-scrollbar;
			// height: calc(100% - 52px);
			height: 100%;
			padding: 18px 12px;
			display: grid;
			align-content: start;
			grid-gap: 14px;
			.chatSelf,
			.chatOther {
				max-width: 66%;
			}
			.chatSelf {
				display: grid;
				grid-gap: 8px;
				grid-template-columns: auto 1fr;
				justify-self: start;

				.msg {
					padding: 5px 16px;
					border-radius: 18px;
					font-size: 15px;
					// background-color: #fbe462;
					background-color: #f6e793;
					white-space: pre-wrap;
					overflow-wrap: anywhere;
					justify-self: start;
				}

				.msg__date {
					display: grid;

					.msg-date {
						font-size: 12px;
						color: #424242;
						margin-right: 8px;
					}
				}
				.MuiAvatar-root {
					width: 36px;
					height: 36px;
				}
			}
			.chatOther {
				justify-self: end;
				display: grid;
				grid-gap: 8px;
				grid-template-columns: 1fr auto;
				.MuiAvatar-root {
					width: 36px;
					height: 36px;
				}
				.msg {
					background-color: #f1f2f3;
					padding: 5px 16px;
					border-radius: 18px;
					font-size: 15px;
					.othreUsername {
						font-size: 12px;
						text-transform: capitalize;
					}
				}

				.msg__date {
					display: grid;

					.msg-date {
						font-size: 12px;
						color: #424242;
						justify-self: end;
						margin-left: 8px;
					}
				}
			}
			&::-webkit-scrollbar {
				width: 8px;
			}
			&::-webkit-scrollbar-track {
				border-radius: 6px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 6px;
			}
		}

		.chatForm {
			min-height: 44px;
			display: grid;
			width: 100%;
			grid-template-columns: 1fr auto;
			border-top: 1px solid rgba(#000, 0.1);
			textarea {
				border: 0;
				border-radius: 0 0 4px 4px;
				background-color: transparent;
				height: 45px;
				line-height: 1.6;
				padding: 8px 12px;
				outline: 0;
				color: inherit;
				font-size: 16px;
				resize: none;
				&::placeholder {
					@include bodyFont-ar;
				}
			}

			.submit-btn {
				border: 0;
				outline: 0;
				padding: 4px 14px;
				align-self: center;
				margin-left: 4px;
				// background-color: #f5eaaf;
				background-color: transparent;
				border-radius: 4px;
				line-height: 1;
				cursor: pointer;
				color: #323232;
				svg {
					transform: rotate(180deg);
					width: 18px;
				}
			}
		}
	}
}
