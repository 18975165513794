@import "../../scss/config";

.visa-warpper {
	background-color: #fff;
	padding: 32px;

	.visa-form {
		img {
			max-width: 100%;
		}
	}
}
.payment-routes-wrap {
	margin-bottom: 42px;
	display: flex;
	gap: 32px;
	a {
		background-color: #ebebeb;
		flex: 1;
		display: grid;
		place-items: center;
		border-radius: 8px;
		padding: 18px 32px;
		max-width: 180px;
		svg {
			height: 18px;
		}

		&.apple-pay-link {
			padding: 20px 32px;
			svg {
				height: 32px;
			}
		}

		&:hover,
		&.active-link {
			background-color: $main-app-color;
			svg {
				fill: #fff;
			}
		}
	}
}
