.more-social-options {
	display: grid;
	grid-gap: 12px;
	justify-content: center;
	align-items: center;
	color: #b2b2b2;

	.options-ul {
		display: grid;
		grid-gap: 18px;
		grid-auto-flow: column;
		justify-content: center;
		align-items: center;
		li {
			display: grid;
			cursor: pointer;
		}
		img {
			width: 32px;
			background-color: #fff;
			box-shadow: 2px 6px 8px rgba(#000, 0.1);
			border-radius: 3px;

			&.google-img {
				width: 38px;
				padding: 5px;
			}
		}
	}
}
