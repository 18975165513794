@import "../../scss/config";

.service-details-page {
	.steps-wrapper {
		background-color: #f8fffc;
		border: 1px solid rgba(#000, 0.08);
		border-radius: 6px;
		padding: 18px 28px;
	}

	.formik-step {
		display: grid;
		align-content: start;
		max-width: 600px;
	}
}

// .personal-img-modal,
.identity-face-img-modal,
.identity-back-img-modal {
	display: grid;
	place-items: center;
	.modal-body {
		overflow-y: auto;
		padding: 18px 14px;
		display: grid;
		grid-template-rows: 1fr auto;
		align-items: center;
		position: absolute;
		background-color: #f7f8fa;
		width: 94%;
		max-width: 600px;
		height: 400px;
		// @include mediaLg {
		// 	width: 600px;
		// 	height: 400px;
		// }
		box-shadow: 0 12px 14px rgba(#000, 0.1), 0 0 30px rgba(#000, 0.1);
		border-radius: 4px;
		border: 0;
		outline: 0;
		.dropZone {
			// width: 500px;
			// height: 240px;
			width: 90%;
			height: 260px;
			margin: auto;
		}

		.previewFiles {
			display: grid;
			place-items: center;
			.imgPreview {
				position: relative;
				display: grid;
				width: 90%;
				max-height: 90%;
				max-width: 500px;
			}
			img {
				// height: 260px;
				max-width: 100%;
				max-height: 220px;
				object-fit: cover;
				object-position: top center;
				// max-width: 500px;
				border: 1px solid rgba(#000, 0.1);
			}

			.icon-cancel {
				position: absolute;
				top: -16px;
				right: -16px;
				background-color: #fff;
				color: #323232;
				width: 32px;
				height: 32px;
				font-size: 22px;
				border-radius: 50%;
				box-shadow: 0 1px 2px rgba(#000, 0.1);
				display: grid;
				place-items: center;
				border: 1px solid rgba(#000, 0.1);
			}
		}

		.done-btn {
			@include bodyFont-ar;
			width: max-content;
			justify-self: center;
		}
	}
}

.prev-works-attatchs-modal {
	.modal-body {
		position: absolute;
		background-color: #f7f8faf1;
		width: 90%;
		height: 80%;
		left: 5%;
		top: 10%;
		box-shadow: 0 12px 14px rgba(#000, 0.1), 0 0 30px rgba(#000, 0.1);
		border-radius: 4px;
		border: 0;
		outline: 0;
		display: grid;
		grid-template-rows: 1fr auto;

		.dropZoneWrapper {
			width: 90%;
			height: 80%;
			display: grid;
			justify-self: center;
			align-self: center;
			& > div {
				display: grid;
			}
		}
		// .dropZone {
		// }

		.previewFiles {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
			padding: 42px;
			grid-gap: 18px;
			grid-auto-flow: dense;
			overflow-y: auto;
			@include custom-scrollbar;

			.imgPreview {
				display: grid;
				position: relative;

				img {
					max-height: 400px;
					width: 100%;
					object-fit: cover;
					border: 1px solid rgba(#000, 0.1);
				}

				.icon-cancel {
					position: absolute;
					top: -11px;
					right: -11px;
					background-color: #fff;
					color: #323232;
					width: 26px;
					height: 26px;
					font-size: 16px;
					border-radius: 50%;
					box-shadow: 0 1px 2px rgba(#000, 0.1);
					display: grid;
					place-items: center;
					border: 1px solid rgba(#000, 0.1);
					line-height: 1;
				}
			}
		}

		.done-btn {
			@include bodyFont-ar;
			width: 102px;
			height: 48px;
			justify-self: center;
			margin-bottom: 32px;
		}
	}
}
