@import "../../scss/config";
.choose-payment {
	background-color: #fff;
	padding: 32px;

	.main-title {
		font-size: 28px;
		color: #000;
		text-align: center;
		line-height: 1.4;
	}
	.sub-title {
		color: $main-app-color;
		text-align: center;
		margin-bottom: 52px;
	}

	.links-wrap {
		display: grid;
		justify-content: center;
		grid-gap: 32px;
		a {
			background-color: #ebebeb;
			display: grid;
			place-items: center;
			border-radius: 8px;
			padding: 28px 92px;
			transition: all 0.2s ease-out;
			svg {
				height: 22px;
			}

			&:hover {
				background-color: $main-app-color;
				svg {
					fill: #fff;
				}
			}

			&.apple-pay-link {
				padding: 20px 62px;
				svg {
					height: 32px;
				}
			}
		}
	}
}
