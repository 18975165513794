@import "../../scss/config";
.payment-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:20px;
    height: 500px;
}
.payment-success {
    color:#4caf50 
}