@import "../../../scss/config";

.desinger-balance {
	max-width: 992px;
	background-color: #fff;
	padding: 32px;
	border-radius: 5px;

	.main-title {
		font-size: 32px;
		text-align: center;
		margin-bottom: 18px;
	}

	.main-content {
		.total-susp-wrap {
			display: grid;
			grid-template-columns: 1fr 40%;
			grid-gap: 22px;

			.total-wrap {
				background-color: $main-app-color;
				color: #fff;
				display: grid;
				grid-gap: 10px;
				border-radius: 4px;
				padding: 18px 12px;
				text-align: center;
				font-size: 22px;
				.total-value {
					font-size: 28px;
				}
				margin-left: 8px;
				&.ltr {
					margin-right: 8px;
				}
			}
			.susp-wrap {
				background-color: #ebebeb;
				display: grid;
				place-content: center;
				text-align: center;
				grid-gap: 10px;

				.susp-value {
					font-size: 28px;
				}
			}
		}

		.msgs-operation-wrap {
			margin-top: 22px;
			display: grid;
			grid-template-columns: 1fr 40%;
			grid-gap: 22px;

			.msgs-wrap {
				max-height: 340px;
				overflow-y: auto;
				@include custom-scrollbar;
				.msg-list-item {
					background-color: #ebebeb;
					padding: 8px 12px;
					border-radius: 4px;
					margin-left: 8px;
					margin-bottom: 22px;
					display: grid;
					grid-template-columns: auto 1fr;
					grid-gap: 10px;
					.msg-avatar {
						width: 45px;
						height: 45px;
						border-radius: 50%;
						object-fit: cover;
						object-position: top center;
					}
					.msg-data {
						font-size: 14px;
						color: #8b8b8b;
						display: grid;
						.desc {
							margin-bottom: 5px;
							.msg-sender {
								color: $main-app-color;
							}
						}
						.msg-date {
							display: flex;
							align-items: center;
							gap: 3px;
							color: $main-app-color;
							font-size: 14px;
							svg {
								color: #8b8b8b;
								height: 20px;
							}
						}
					}

					&.ltr {
						margin-right: 8px;
					}
				}
			}

			.operation-wrap {
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				background-color: #fff;
				border: 2px dashed rgba(#707070, 0.6);
				border-radius: 3px;
				padding: 12px 16px;
				margin-bottom: 22px;
				.top-wrap {
					display: grid;
					margin-bottom: 12px;
					.wrap-title {
						text-align: center;
						margin-bottom: 10px;
						font-size: 18px;
					}
					input {
						border: 0;
						outline: 0;
						background-color: #ebebeb;
						color: #8d8d8d;
						font-family: inherit;
						border-radius: 5px;
						padding: 12px;
						width: 100%;
						font-size: 18px;
						&::placeholder {
							font-size: 16px;
						}
					}
				}
				.bottom-wrap {
					display: flex;
					justify-content: center;
					flex-direction: column;
					.wrap-title {
						text-align: center;
						margin-bottom: 10px;
						font-size: 18px;
					}
				}
			}
		}
	}
}


.desinger-balance.transactions .main-content .msgs-operation-wrap  {
	grid-template-columns: 1fr ;
}

.withdraw-history-item {

	background-color: #ebebeb;
	padding: 8px 12px;
	border-radius: 4px;
	margin-left: 8px;
	margin-bottom: 22px;
}
.flex-center {
	display: flex;
	align-items: center;
	gap: 6px;
}