@import "../../../scss/config";

.application-step-three {
	.radios-wrap {
		margin-bottom: 32px;
		.radios-title {
			margin-bottom: 14px;
			color: $main-app-color;
			font-size: 20px;
		}
		label {
			color: #64ae4a;
		}
	}

	.upload-wrap {
		margin-bottom: 32px;
		.title-input-wrap {
			.label-input {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 12px;
				margin-bottom: 14px;
				.upload-file-label {
					cursor: pointer;
					line-height: 1;
					background-color: #efefef;
					border-radius: 5px;
					padding: 12px 16px;
					display: flex;
					flex-wrap: wrap;
					width: fit-content;

					input {
						display: none;
					}

					.icon-text {
						display: flex;
						gap: 5px;
						align-items: center;
					}
				}
			}

			.file-list {
				display: flex;
				flex-wrap: wrap;
				gap: 14px;

				.file-item {
					background-color: #efefef;
					border-radius: 5px;
					width: fit-content;
					display: flex;
					align-items: center;
					gap: 8px;
					line-height: 1;
					padding: 11px 14px;
				}
			}
		}
	}
}
