@import "../../../scss/config";

.service-step-ten {
  .checkBoxs-title-wrap {
    display: grid;
    .checkboxs-title {
      font-size: 18px;
      margin-bottom: 22px;
      color: $main-app-color;
      font-weight: bold;
    }

    .error-alert {
      margin: 12px 0;
    }

    .checkboxs-wrap {
      display: grid;
      grid-gap: 22px;
      .checkbox-input-wrap {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 8px;
        align-items: start;

        .text-field-errors {
          display: grid;
        }
      }
    }
  }
  .font-group-wrapper,
  .color-group-wrapper {
    margin-top: 52px;
    display: grid;
    align-content: start;
    .group-title {
      font-size: 18px;
      margin-bottom: 12px;
      color: $main-app-color;
    }
    .yes-wrap {
      display: flex;
      justify-content: start;
      align-items: center;
      grid-gap: 8px;
      flex-wrap: wrap;

      .upload-file-label {
        cursor: pointer;
        line-height: 1;
        background-color: #efefef;
        border-radius: 5px;
        padding: 12px 16px;
        word-break: break-all;
        input {
          display: none;
        }

        .icon-text {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }
    }
  }
}
