@import '../../../scss/config';

.service-step-zero {
  .about-wrapper,
  .recognition-wrapper,
  .desc-wrapper {
    display: grid;
    gap: 8px;
  }
  .wrapper-label {
    color: $main-app-color;
    font-size: 20px;
  }
}
