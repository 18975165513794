@import "../../scss/config";

.forget-password-page {
	background-color: #f1f2f3;
	min-height: 100vh;
	display: grid;
	place-items: center;
	.mfa-container {
		display: grid;
		justify-items: center;
	}
	.forget-password-wrap {
		display: grid;
		place-items: center;
		background-color: #fff;
		max-width: 532px;
		width: 100%;
		padding: 42px 18px;
		@include mediaMd {
			padding: 52px;
		}
		border-radius: 8px;
		border: 1px solid rgba(#000, 0.1);
		.forget-password-form {
			width: 100%;
			display: grid;
			margin-bottom: 32px;
			grid-gap: 28px;
		}

		.send-btn {
			button {
				padding: 10px 22px;
			}
		}
		.send-resend-btns {
			display: flex;
			gap: 12px;
			flex-wrap: wrap;
			align-items: stretch;
		}
	}
}
