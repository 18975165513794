@import "../../scss/config";

.shared-btn {
	padding: 10px 18px;
	font-size: 14px;
	cursor: pointer;
	@include mediaLg {
		padding: 15px 32px;
		font-size: 18px;
	}
	@include mediaXXlg {
		padding: 18px 36px;
	}
	background-color: $main-app-color;
	color: #fff;
	border-radius: 5px;
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	grid-gap: 6px;
	line-height: 1;
	&.rtl {
		span {
			transform: translateX(-14px);
		}
		img:not(.loading-img) {
			transform: translate(100%);
		}
	}
	&.ltr {
		span {
			transform: translateX(14px);
		}
		img:not(.loading-img) {
			transform: translate(-100%);
		}
	}
	span {
		transition: all 0.25s ease-out;
	}
	img:not(.loading-img) {
		width: 20px;
		max-height: 20px;
		// @include mediaLg {
		// 	width: 22px;
		// }
		opacity: 0;
		pointer-events: none;
		transition: all 0.25s ease-out;
	}

	.loading-img {
		height: 20px;
	}

	&:hover {
		span {
			transform: translateX(0) !important;
		}
		img:not(.loading-img) {
			transform: translate(0) !important;
			opacity: 1;
		}
	}

	&.is-submitting {
		pointer-events: none;
		background-color: rgba($main-app-color, 0.52);
		&.is-submitting {
			span {
				transform: translateX(0) !important;
			}
		}
	}
}
