@import "../../../scss/config";

.service-step-two {
	grid-gap: 32px;

	.radio-group-wrapper {
		.group-title {
			color: $main-app-color;
			font-size: 22px;
			font-weight: bold;
		}
	}
}
