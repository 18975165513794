@import "../../scss/config";

.dashboard-page {
	background-color: #f8f8f8;
	padding: 52px 0;

	.dashboard-wrapper {
		display: grid;
		@include mediaLg {
			grid-template-columns: 280px 1fr;
		}
		grid-gap: 22px;
	}
	.dashboard-wrapper.not-activated {
		grid-template-columns:  1fr;

	}
	.services-list {
		margin-bottom: 62px;
		display: grid;
		grid-gap: 78px 20px;
		@include mediaMd {
			grid-template-columns: repeat(2, 1fr);
		}
		@include mediaXlg {
			grid-template-columns: repeat(6, 1fr);
		}
		@include mediaXXlg {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
