@import "../../../scss/config";

.messages-users-wrap {
	display: grid;
	grid-template-columns: auto 1fr;
	min-height: 60vh;
}

.users-wrap {
	background-color: #ebebeb;
	border-radius: 5px;
	max-width: 314px;
	padding: 12px 14px;
	height: 60vh;
	overflow: auto;
	padding-bottom: 150px;
	@media screen and (max-width :960px) {
			grid-column: 2;
			max-width: 100%;
			height: max-content;
			display: flex;
			gap: 10px;
			overflow-x: auto;
			
		
	}
	.msg-list-item {
		width: 100%;
		display: grid;
		grid-template-columns: auto 1fr;
		justify-content: start;
		grid-gap: 12px;
		align-items: center;
		background-color: #fff;
		border-radius: 5px;
		margin-bottom: 8px;
		border: 1px solid rgba(#000, 0.08);
		padding: 8px 14px;
		overflow: auto;
		cursor: pointer;
		@media screen and (max-width :960px) {
			width: 300px;
			min-width: 200px;
			overflow: visible;
			justify-items: center;
			.MuiAvatar-root,.msg-data {
				grid-column: 2;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 12px;
			}
		
	}

		.MuiAvatar-root {
			width: 48px;
			height: 48px;
		}
		.MuiAvatar-img {
			width: 48px;
			height: 48px;
		}

		.msg-data {
			justify-self: start;
		}

		.msg-title {
			margin-bottom: 2px;
			color: $main-app-color;
			max-width: 208px;
		}
		.msg-desc {
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 208px;
			overflow: hidden;
			font-size: 14px;
		}
		.msg-date {
			display: flex;
			align-items: center;
			gap: 8px;
			color: #8b8b8b;
		}
	}
}
.messages-page {
	overflow: hidden;
	position: relative;
	height: 60vh;
	.message-box-wrap {
		min-width: 200px;
		max-width: 200px;
		.msg-created-at {
			font-size: 12px;
		}
	}

	.p-4 {
		padding: 1rem;
	}
	.newMessage-input {
		width:80%
	}
	.iconButton {
		padding:10px;
		background-color: $main-app-color;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color:white;
		width: max-content;
	}
	.iconButton:hover {
		background-color:$main-app-color ;
	}
	.flex-between {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap:5px;
		width: 100%;
	}
	.flex-column {
		display: flex;
		flex-direction: column;
	}
	.rotate-180 {
		transform: rotate(180deg);
	}
.messages-warp {
	height: 85%;
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: relative;
	padding-bottom: 30px;
}
.padding-b-0 {
	padding-bottom: 0 !important;
}

}


.empty-wrapper h2 {
	background: white;
    padding: 20px;
    border-radius: 10px;  }

.messages-wrap-container {
	height: 60vh;
	@media screen and (max-width :960px) {
			grid-column: 2;
	}
}

.newMessage-container {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0%;
}