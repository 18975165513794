@import "../../../scss/config";
.my-orders-tab {
  display: grid;
  grid-gap: 32px;
  max-width: 1100px;
  align-content: start;
}
.content-filter {
  display: flex;
  margin-bottom: 32px;
  .filter-link {
    padding: 8px 22px;
    position: relative;

    &:last-of-type {
      &::after {
        display: none;
      }
    }
    &::after {
      content: "";
      width: 1px;
      background-color: rgba(#000, 0.3);
      position: absolute;
      right: 100%;
      height: 50%;
      top: 25%;
    }

    &.active {
      color: $main-app-color;
    }
  }
}

