.application-step-one {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 22px;

	.upload-wrap {
		grid-column: 1 / -1;
		.title-input-wrap {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 22px;
		}
		.upload-file-label {
			cursor: pointer;
			line-height: 1;
			background-color: #efefef;
			border-radius: 5px;
			padding: 12px 16px;
			display: flex;
			flex-wrap: wrap;
			width: fit-content;

			input {
				display: none;
			}

			.icon-text {
				display: flex;
				gap: 5px;
				align-items: center;
			}
		}
	}
}
.country-label {
	background: #f8fffc;
    z-index: 100;
    width: max-content;
    padding: 0 7px;
}