@import "../../scss/config";

.custom-app-bar {
	@include mainAppBarHeight;
	transform: translateY(0);
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	transition: all 0.25s ease-out;
	display: grid;
	align-items: center;

	&.exceeds0 {
		border-radius: 0 0 12px 12px;
		box-shadow: 0 12px 14px rgba(#000, 0.1);
		background-color: #fff;
		z-index: $app-header-z-index;
		// &.scroll-up {
		// }
		&.scroll-down {
			transform: translateY(-100%);
		}
	}
}
