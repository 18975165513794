@import "../../../scss/config";

.service-step-one {
	.vid-duration-section {
		color: $main-app-color;
		.btn-label {
			font-weight: bold;
			margin-bottom: 18px;
			font-size: 20px;
		}
		.btn-helper-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 14px;
		}
		.vid-duration-btn {
			background-color: #ebebeb;
			justify-self: start;
			width: fit-content;
			padding: 10px 14px;
			border-radius: 999px;
			display: grid;
			grid-auto-flow: column;
			align-items: center;
			grid-gap: 22px;
			font-size: 18px;
			font-weight: bold;
			svg {
				cursor: pointer;
				color: #000;
			}

			&.rtl {
				.count-down {
					order: 1;
				}
				.count-up {
					order: -1;
				}
			}
		}
	}

	// script-is-ready-section
	.script-is-ready-section {
		display: grid;
		.yes-no-section {
			display: grid;
			grid-gap: 52px;
			.yes-section {
				display: grid;

				.yes-row {
					display: flex;
					align-items: center;
					margin-bottom: 22px;
					label {
						.MuiTypography-body1 {
							font-size: 24px;
						}
					}

					.helper-text {
						font-size: 14px;
						color: #8b8b8b;
					}
				}

				.textarea-section {
					padding-bottom: 32px;
					border-bottom: 1px dashed #8b8b8b;
					grid-gap: 22px;
					display: grid;
					grid-template-columns: auto auto;
					.script-textarea {
						grid-column: 1 / -1;
						width: 100%;
					}
					textarea {
						line-height: 2;
					}

					// .words-count-input,
					// .vid-input-wrap {
					// 	justify-self: start;
					// }
					.vid-input-wrap {
						display: flex;
						align-items: center;
						gap: 6px;
						.helper-span {
							color: #8b8b88;
							font-size: 14px;
						}
					}

					.accept-review-btns {
						grid-column: 1 / -1;
						display: grid;
						grid-template-columns: auto auto;
						justify-content: start;
						align-items: start;
						grid-gap: 22px;
						label {
							.MuiTypography-body1 {
								font-size: 15px;
								color: #0984e3;
							}
						}

						.review-wrap {
							align-items: start;
							display: grid;
							grid-template-columns: auto 1fr;
							.helper-span {
								color: #8b8b8b;
								font-size: 14px;
							}
						}
					}
				}
			}

			.no-section {
				display: grid;

				.no-row {
					display: flex;
					align-items: center;
					label {
						.MuiTypography-body1 {
							font-size: 24px;
						}
					}

					.helper-text {
						font-size: 14px;
						color: #8b8b8b;
					}
				}

				.textarea-section {
					margin-top: 18px;
					display: grid;
					grid-gap: 14px;
					.breif-title {
						color: $main-app-color;
					}
					textarea {
						line-height: 2;
					}
				}
			}
		}
	}
}
